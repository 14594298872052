html, body {
  font-family: 'Stylish', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

:root {
  --color-bg1: rgb(9, 10, 26);
  --color-bg2: rgb(23, 12, 32);
  --color1: 18, 255, 180;
  --color2: 136, 160, 252;
  --color3: 210, 222, 50;
  --color4: 77, 143, 1;
  --color5: 242, 126, 80;
  --color6: 76, 254, 73;
  --color7: 255, 255, 255;
  --color-interactive: 227, 155, 54;
  --circle-size: 80%;
  --blending: hard-light;
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.gradient-bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0;

  svg {
    display: none;
  }

  .gradients-container {
    filter: url(#goo) blur(40px);
    width: 100%;
    height: 100%;
  }

  .g1, .g2, .g3, .g4, .g5 {
    position: absolute;
    width: var(--circle-size);
    height: var(--circle-size);
    background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 44%) no-repeat;
    mix-blend-mode: var(--blending);
    transform-origin: center center;
    opacity: .6;
  }

  .g1 {
    background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 44%) no-repeat;
    animation: moveVertical 30s ease infinite;
  }

  .g2 {
    background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 44%) no-repeat;
    animation: moveInCircle 20s reverse infinite;
    transform-origin: calc(50% - 400px);
  }

  .g3 {
    background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 44%) no-repeat;
    animation: moveInCircle 40s linear infinite;
    transform-origin: calc(50% - 400px);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);
  }

  .g4 {
    background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 44%) no-repeat;
    animation: moveHorizontal 40s ease infinite;
    transform-origin: calc(50% - 200px);
    opacity: 0.7;
  }

  .g5 {
    background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 44%) no-repeat;
    animation: moveInCircle 20s ease infinite;
    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    transform-origin: calc(50% - 800px) calc(50% + 200px);
  }

  .g6 {
    background: radial-gradient(circle at center, rgba(var(--color6), 0.8) 0, rgba(var(--color6), 0) 44%) no-repeat;
    animation: moveVertical 50s linear infinite;
    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    transform-origin: calc(50% + 200px) calc(50% - 400px);
  }

  .g7 {
    background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 44%) no-repeat;
    animation: moveHorizontal 40s ease infinite;
    transform-origin: calc(50% - 800px) calc(50% + 200px);
    opacity: 0.7;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 44%) no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: -50%;
    left: -50%;
    opacity: .45;
  }
}

button {
  z-index: 1000;
}