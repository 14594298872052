.sec-text-container-previous {
  z-index: 90;
  padding-top: 60px;
  width: 0vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  color: white;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  user-select: none;
  text-align: center;
  filter: blur(10px);
  opacity: 0.5;
  transform: rotate(-5deg);
}

.sec-text-container-next {
  z-index: 90;
  padding-top: 60px;
  width: 0vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  color: white;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  user-select: none;
  text-align: center;
  filter: blur(10px);
  opacity: 0.5;
  transform: rotate(5deg);
}


.sec-album-info {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sec-album-name-text {
  color: rgba(255, 255, 255, 0.8);
  font-size: 25px;
  margin-bottom: -5px;
}

.sec-album-artist-text {
  color: rgba(255, 255, 255, 0.6);
  font-size: 35px;
  margin-bottom: 30px;
}

.sec-plaque-frosted-glass {
  position: relative;
  z-index: 0;
  background: linear-gradient(130deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.35);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sec-album-cover-container {
  width: 400px; /* Fixed size for album cover */
  height: 400px; /* Fixed size for album cover */
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin-bottom: 15px;
}

.sec-album-cover-container img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
