.album-display {
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.album-display button {
  margin: 0 300px;
  padding: 10px 10px;
  font-size: 45px;
  font-family: 'Lilita One', sans-serif;
  background-color: transparent;
  border: none;
  color: rgba(255, 255, 255, 0.4);
}

.album-display button:hover {
  color: rgba(255, 255, 255, 0.65);
}

.album-display button:active {
  color: rgba(255, 255, 255, 0.8);
}

.album-display button:disabled {
  color: transparent;
}