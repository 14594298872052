.text-container {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  color: white;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
  user-select: none;
  text-align: center;
}

.album-info {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.album-name-text {
  color: rgba(255, 255, 255, 0.85);
  font-size: 25px;
  margin-bottom: -5px;
}

.album-artist-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 35px;
  margin-bottom: 30px;
}

.plaque-frosted-glass {
  position: relative;
  z-index: 0;
  background: linear-gradient(130deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.45);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.album-cover-container {
  width: 400px; /* Fixed size for album cover */
  height: 400px; /* Fixed size for album cover */
  overflow: hidden;
  position: relative;
  z-index: 2;
  margin-bottom: 15px;
}

.album-cover-container img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
